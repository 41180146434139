<template>
  <v-icon :icon="icon" :color="color"></v-icon>
</template>
<script setup>

import {computed, defineProps} from "vue";

const icon = computed(() => {
  if(props.modelValue) return 'mdi-' + props.trueIcon
  else return 'mdi-' + props.falseIcon
})

const color = computed(() => {
  if(props.modelValue) return props.trueColor
  else return props.falseColor
})

const props = defineProps({
  falseColor: {
    type: String,
    default: "rgb(142,56,56)"
  },
  trueColor: {
    type: String,
    default: "rgb(56, 142, 60)"
  },
  falseIcon: {
    type: String,
    default: "close-thick"
  },
  trueIcon: {
    type: String,
    default: "check-bold"
  },
  modelValue: {
    type: Boolean,
    default: false
  }
})
</script>