<template>
  <link rel="stylesheet" href="https://cdn.datatables.net/1.13.1/css/jquery.dataTables.min.css">
  <v-app>

    <v-app-bar
        app
        clipped-left
    >
      <v-app-bar-nav-icon
          @click.stop="switchForceMenu"
          class="d-lg-none"
      >
      </v-app-bar-nav-icon>
      <v-toolbar-title><router-link :to="{Name:'Seller'}"><v-img src="./assets/img/logo.png" width="300px"></v-img></router-link></v-toolbar-title>
      <v-card variant="flat">
      </v-card>
    </v-app-bar>

    <v-navigation-drawer
        v-model="displayDrawer"
        expand-on-hover
        :rail="vuetify.display.lgAndUp.value"
    >
      <v-list
          density="compact"
          nav
      >
        <v-list-item
            link
            prepend-icon="mdi-truck"
            title="Fournisseurs"
            value="Seller"
            @click="noForceMenu('Seller')"
        >
        </v-list-item>
        <v-list-item
            link
            prepend-icon="mdi-dice-6"
            title="Jeux"
            value="Game"
            @click="noForceMenu('Game')"
        >
        </v-list-item>
        <v-list-item
            link
            prepend-icon="mdi-storefront-outline"
            title="Éditeurs"
            value="Editor"
            @click="noForceMenu('Editor')"
        >
        </v-list-item>
        <v-list-item
            link
            prepend-icon="mdi-shape-plus"
            title="Catégories"
            value="Category"
            @click="noForceMenu('Category')"
        >
        </v-list-item>
        <v-list-item
            link
            prepend-icon="mdi-treasure-chest"
            title="Trésorerie"
            value="Treso"
            @click="noForceMenu('Treso')"
        >
        </v-list-item>
        <v-list-item
            link
            prepend-icon="mdi-warehouse"
            title="Valeur du stock"
            value="Money"
            @click="noForceMenu('Money')"
        >
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <router-view :key="$route.path" />
    </v-main>
  </v-app>
</template>
<script setup>

import {useRoute, useRouter} from "vue-router"
import vuetify from "@/plugins/vuetify"
import {ref} from "vue";

const route = useRoute()
const router = useRouter()

const forceMenu = ref(false)
const displayDrawer = ref(vuetify.display.lgAndUp.value)

function switchForceMenu() {
  forceMenu.value = !forceMenu.value
  displayDrawer.value = vuetify.display.lgAndUp.value || forceMenu.value
}

function noForceMenu(routerName) {
  forceMenu.value = false
  router.push({name:routerName})
}

</script>
<style>
.dataTableNewLine {
  background-color: hsl(99, 100%, 90%);
}
.dataTableModifiedLine {
  background-color: hsl(56, 100%, 90%);
}
</style>
