<template>
  <crud-form
      table-title="Jeux"
      v-model="filteredGames"
      :headers="headers"
      :fields="fields"
      :default-values="{
        name: '',
        ean13: '',
        sellerRef: '',
        idSeller: 0,
        idEditor: 0,
        idCategory: 0,
        idTax: 0,
        buyPrice: 0,
        sellPriceHT: 0,
        hiboutikId: 0,
        activated: false,
        stock: 0,
        expectedStock: 0
      }"
      :actions="{
        edit: true,
        delete: false,
        new: true
      }"
      table-name="game"
      :items-per-page="100"
  >

    <template v-slot:[`item.seller`]="{ item }">
      {{item.seller.name}}
    </template>
    <template v-slot:[`item.category`]="{ item }">
      {{item.category.name}}
    </template>
    <template v-slot:[`item.stock`]="{ item }">
      {{item.stock}}/{{item.expectedStock}}
    </template>
    <template v-slot:[`item.buyPrice`]="{ item }">
      {{monetary(item.buyPrice)}}
    </template>
    <template v-slot:[`item.activated`]="{ item }">
      <boolean v-model="item.activated"></boolean>
    </template>

    <template v-slot:additionalHeaderButtonsBefore>
      <v-text-field
          v-model="gamesFilter"
          label="Filtre"
          prepend-inner-icon="mdi-magnify"
          class="gamesFilter"
      ></v-text-field>
      <v-switch
          v-model="activatedFilter"
          label="Activé"
          color="primary"
          hide-details
      ></v-switch>
    </template>

    <template v-slot:additionalActionsBefore="{slotData}">
      <v-icon
          v-if="!activatedFilter"
          size="large"
          class="me-2"
          color="#00BFFF"
          @click.stop="sendToHiboutik(slotData)"
      >
        mdi-owl
      </v-icon>

    </template>

    <template v-slot:form="{item, errors}">
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  v-model="item.name"
                  :error-messages="errors.name"
                  label="Nom"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  v-model="item.ean13"
                  :error-messages="errors.ean13"
                  label="Ean13"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  v-model="item.sellerRef"
                  :error-messages="errors.sellerRef"
                  label="Seller Ref"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-select
                  v-model="item.idSeller"
                  :items="sellers"
                  item-title="name"
                  item-value="id"
                  label="Fournisseur"
                  :error-messages="errors.idSeller"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-select
                  v-model="item.idCategory"
                  :items="categories"
                  item-title="name"
                  item-value="id"
                  label="Categorie"
                  :error-messages="errors.idCategory"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-select
                  v-model="item.idEditor"
                  :items="editors"
                  item-title="name"
                  item-value="id"
                  label="Éditeur"
                  :error-messages="errors.idEditor"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-select
                  v-model="item.idTax"
                  :items="taxes"
                  item-title="amount"
                  item-value="id"
                  label="TVA"
                  :error-messages="errors.idTax"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="6"
                md="6"
            >
              <v-text-field
                  v-model.number="item.stock"
                  :error-messages="errors.stock"
                  label="Stock"
                  type="number"
              ></v-text-field>
            </v-col>
            <v-col
                cols="12"
                sm="6"
                md="6"
            >
              <v-text-field
                  v-model.number="item.expectedStock"
                  :error-messages="errors.expectedStock"
                  label="Stock attendu"
                  type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  v-model.number="item.buyPrice"
                  :error-messages="errors.buyPrice"
                  label="Prix d'achat HT"
                  type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  v-model.number="item.sellPriceHT"
                  :error-messages="errors.sellPriceHT"
                  label="Prix de vente HT"
                  type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  v-model.number="item.hiboutikId"
                  :error-messages="errors.hiboutikId"
                  label="Hiboutik Id"
                  type="number"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-switch
                  v-model="item.activated"
                  label="Activé"
                  color="primary"
                  hide-details
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </template>

  </crud-form>
</template>
<script setup>

import CrudForm from "@/components/elements/CrudForm.vue"
import {computed, ref, watch} from "vue";
import {empty, jsonQuery, monetary} from "@/helpers";
import Boolean from "@/components/elements/Boolean.vue";

const games = ref([])
const fields = ref(`
    id
    name
    ean13
    sellerRef
    idSeller
    seller {
      name
    }
    idEditor
    editor {
      name
    }
    idCategory
    category {
      name
    }
    idTax
    tax {
      amount
    }
    buyPrice
    sellPriceHT
    hiboutikId
    activated
    stock
    expectedStock
    sellPriceTTC
    toBeOrdered
    qtyToOrder
`)

const activatedFilter = ref(true)

const categories = ref([])
const editors = ref([])
const sellers = ref([])
const taxes = ref([])

jsonQuery(`
query CategorysAll {
  categorysAll {
    id
    name
  }
}
`).then(res => {
  categories.value = res.data.categorysAll
})

jsonQuery(`
query EditorsAll {
  editorsAll {
    id
    name
  }
}
`).then(res => {
  editors.value = res.data.editorsAll
})

jsonQuery(`
query SellersAll {
  sellersAll {
    id
    name
  }
}
`).then(res => {
  sellers.value = res.data.sellersAll
})

jsonQuery(`
query TaxsAll {
  taxsAll {
    id
    amount
  }
}
`).then(res => {
  taxes.value = res.data.taxsAll
})

let gamesList = new Map()

function refreshGames() {

    jsonQuery(`
query GamesAll($activated: Boolean) {
  gamesAll(activated: $activated) {
    ${fields.value}
  }
}
`, {activated: activatedFilter.value}).then(res => {
      gamesList.set(activatedFilter.value, res.data.gamesAll)
      games.value = res.data.gamesAll
      filterGames()
    })
}
refreshGames()

const gamesFilter = ref('')
let filterLastAction = new Date().getTime()
const filteredGames = ref([])
const FILTER_TIME = 1000

function filterGames() {
  if(gamesFilter.value.trim().length < 3) filteredGames.value = games.value
  else {
    let time = new Date().getTime()
    if(filterLastAction > time - FILTER_TIME) {
      setTimeout(filterGames, FILTER_TIME - (time - filterLastAction))
    }
    else {
      filterLastAction = new Date().getTime()
      filteredGames.value = games.value.filter(game => {
        return game.name.toLowerCase().includes(gamesFilter.value.toLowerCase()) ||
        game.ean13.trim() === gamesFilter.value ||
        game.seller.name.toLowerCase().includes(gamesFilter.value.trim().toLowerCase()) ||
        game.category.name.toLowerCase().includes(gamesFilter.value.trim().toLowerCase())

      })
    }
  }
}
filterGames()

watch(activatedFilter, (newValue) => {refreshGames()})
watch(gamesFilter, (newValue) => {filterGames()})

function sendToHiboutik(game) {
  jsonQuery(`
  mutation Mutation($idGame: Int!) {
    sendGameToHiboutik(idGame: $idGame) {
      ${fields.value}
    }
  }
  `, {idGame: game.id}).then(res => {
    game = res.data.sendGameToHiboutik
    gamesList = new Map()
    activatedFilter.value = true
    refreshGames()
  })
}

const headers = ref([
  {
    title: 'Nom',
    align: 'start',
    sortable: true,
    key: 'name'
  },
  {
    title: 'EAN13',
    align: 'start',
    sortable: true,
    key: 'ean13'
  },
  {
    title: 'Fournisseur',
    align: 'start',
    sortable: true,
    key: 'seller.name'
  },
  {
    title: 'Categorie',
    align: 'start',
    sortable: true,
    key: 'category.name'
  },
  {
    title: 'Achat HT',
    align: 'start',
    sortable: true,
    key: 'buyPrice'
  },
  {
    title: 'H Id',
    align: 'start',
    sortable: true,
    key: 'hiboutikId'
  },
  {
    title: 'Activé',
    align: 'start',
    sortable: true,
    key: 'activated'
  },
  {
    title: 'Stock',
    align: 'start',
    sortable: true,
    key: 'stock'
  }
])

function colorOrder(item) {
  if(item.isReadyMinimumFranco) return '#41b222'
  else if(item.isReadyMinimumOrder) return '#b2a622'
  else return '#B22222'
}

</script>

<style scoped>
.gamesFilter {
  max-width: 250px;
}
</style>