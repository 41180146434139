<template>
  <crud-form
      table-title="Catégories"
      v-model="categories"
      :headers="headers"
      :fields="fields"
      :default-values="{
        name: '',
        hiboutikId: 0
      }"
      table-name="category"
  >
    <template v-slot:form="{item, errors}">
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  v-model="item.name"
                  :error-messages="errors.name"
                  label="Nom"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  v-model="item.hiboutikId"
                  :error-messages="errors.hiboutikId"
                  label="Hiboutik Id"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </template>

  </crud-form>
</template>

<script setup>

import CrudForm from "@/components/elements/CrudForm.vue"
import {ref} from "vue"
import {jsonQuery} from "@/helpers";

const categories = ref([])

const fields = ref(`
    id
    name
    hiboutikId
`)

function refreshCategories() {
  jsonQuery(`
query CategorysAll {
  categorysAll {
    ${fields.value}
  }
}
`).then(res => {
    categories.value = res.data.categorysAll
  })
}
refreshCategories()

const headers = ref([
  {
    title: 'Nom',
    align: 'start',
    sortable: true,
    key: 'name'
  },
  {
    title: 'HiboutikId',
    align: 'start',
    sortable: true,
    key: 'hiboutikId'
  }
])
</script>