<template>
  <crud-form
      table-title="Scénarios de trésorerie"
      v-model="tresos"
      :headers="headers"
      :fields="fields"
      :default-values="{
        name: '',
        yaml: ''
      }"
      table-name="treso"
      :items-per-page="100"
  >
    <template v-slot:additionalActions="{slotData}">
      <v-btn
          icon="mdi-chart-line"
          size="x-small"
          @click.stop="showChart(slotData)"
          title="Voir la courbe de tréso"
          class="bg-blue"
      >
      </v-btn>
    </template>

    <template v-slot:[`item.min`]="{ item }">
      <monetary
        v-model="item.data.min"
      ></monetary>
    </template>

    <template v-slot:[`item.max`]="{ item }">
      {{monetary(item.data.max)}}<br />{{item.data.maxDate}}
    </template>

    <template v-slot:[`item.totalIn`]="{ item }">
      {{monetary(item.data.totalIn)}}
    </template>

    <template v-slot:[`item.totalOut`]="{ item }">
      {{monetary(item.data.totalOut)}}
    </template>

    <template v-slot:form="{item, errors}">
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-text-field
                  v-model="item.name"
                  :error-messages="errors.name"
                  label="Nom"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col
                cols="12"
                sm="12"
                md="12"
            >
              <v-textarea
                  v-model="item.yaml"
                  :label="'YAML'"
                  :error-messages="errors.yaml"
                  :rows="27"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </template>
  </crud-form>

  <v-dialog
      v-model="chartDisplay"
      style="z-index:1007;"
      scrollable
  >
    <v-card>
      <v-card-title
          style="position:fixed;z-index:1008;width:100%"
          variant="elevated"
      >
        Graphique jour par jour
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-sparkline
              :model-value="chartValue"
              :line-width="1"
              auto-draw
          >
            <template v-slot:label="{index, value}">
              {{displayableValue(index, value)}}
            </template>
          </v-sparkline>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script setup>

import CrudForm from "@/components/elements/CrudForm.vue"
import Monetary from "@/components/elements/Monetary.vue"
import {computed, ref} from "vue"
import {jsonQuery, monetary} from "@/helpers";
import Boolean from "@/components/elements/Boolean.vue";

const headers = ref([
  {
    title: 'Nom',
    align: 'start',
    sortable: true,
    key: 'name'
  },
  {
    title: 'From',
    align: 'start',
    sortable: true,
    key: 'data.from'
  },
  {
    title: 'To',
    align: 'start',
    sortable: true,
    key: 'data.to'
  },
  {
    title: 'Min',
    align: 'start',
    sortable: true,
    key: 'min'
  },
  {
    title: 'Max',
    align: 'start',
    sortable: true,
    key: 'max'
  },
  {
    title: 'Total In',
    align: 'start',
    sortable: true,
    key: 'totalIn'
  },
  {
    title: 'Total Out',
    align: 'start',
    sortable: true,
    key: 'totalOut'
  }
])

const minLabelIndex = ref(0)
const minLabel = ref(null)
const minDate = ref(null)
const chartDisplay = ref(false)
const currentTreso = ref(null)
const tresos = ref([])
const fields = ref(`
    id
    name
    yaml
    data {
      min
      max
      minDate
      maxDate
      from
      to
      totalIn
      totalOut
      days {
        amount
        day
        details {
          amount
          name
        }
      }
    }
`)

function displayableValue(index, value) {
  if(index === minLabelIndex.value) return monetary(value)
  else return ""
}

function refreshTresos() {

  return jsonQuery(`
query TresosAll {
  tresosAll {
    ${fields.value}
  }
}
`).then(res => {
    tresos.value = res.data.tresosAll
  })
}
refreshTresos()

const chartValue = computed(() => {
  let ret = []
  for(let day of currentTreso.value.data.days) ret.push(day.amount)
  return ret
})

function showChart(treso) {
  currentTreso.value = treso
  let index = 0
  minLabel.value = null
  minLabelIndex.value = 0
  for(let day of currentTreso.value.data.days) {
    if(minLabel.value === null || day.amount < minLabel.value) {
      minLabel.value = day.amount
      minLabelIndex.value = index
      minDate.value = day.day
    }
    index++
  }
  chartDisplay.value = true
}

</script>