<template>
<span v-bind:class="[negative ? 'negative' : '']">{{ monetary(props.modelValue) }}</span>
</template>

<script setup>

import {defineProps, computed} from "vue"
import {monetary} from "../../helpers"

const props = defineProps({
  modelValue: {
    type: Number,
    default: 0
  }
  })

const negative = computed(() => props.modelValue < 0)
</script>
<style>
.negative {
    color: red;
}
</style>