<template>
  <v-toolbar
      v-if="!props.hideTitle"
      flat
  >
    <v-toolbar-title>{{ props.tableTitle }}</v-toolbar-title>
    <v-spacer></v-spacer>
    <slot name="additionalHeaderButtonsBefore"></slot>
    <v-btn
        v-if="props.actions.new"
        icon="mdi-plus"
        size="x-small"
        @click.stop="clickBtNew"
        :title="props.buttonNewText"
        class="bg-green"
    >
    </v-btn>
    <slot name="additionalHeaderButtons"></slot>

  </v-toolbar>

  <v-data-table
      :headers="tableHeaders"
      :items="props.modelValue"
      :sort-by="props.sortBy"
      class="elevation-1"
      :items-per-page="props.itemsPerPage"
  >
    <template v-slot:item="itemData">
      <tr :class="trClasses(itemData.item)" @click.stop="clickRow(itemData.item)">
        <td v-for="column in itemData.columns" v-bind:key="column.key" :class="tdClasses(column)">
          <span v-if="column.key !== 'actions' && !(column.key in columnSlots)">
            {{itemData.internalItem.columns[column.key]}}
          </span>
          <span v-else-if="column.key !== 'actions' && column.key in columnSlots">
              <slot :name="'item.' + column.key" :item="itemData.item"></slot>
          </span>
          <span v-else>
              <slot name="additionalActionsBefore" :slotData="itemData.item"></slot>
              <v-card-text
                  style="display:inline;white-space: nowrap;padding:0"
              >
                <v-icon
                    v-if="props.actions.edit"
                    size="large"
                    class="me-2"
                    color="#DAA520"
                    @click.stop="openForm(itemData.item)"
                >
                  mdi-pencil
                </v-icon>
                <v-icon
                    v-if="props.actions.delete"
                    size="large"
                    color="#B22222"
                    @click.stop="openFormDelete(itemData.item)"
                >
                  mdi-delete
                </v-icon>
              </v-card-text>
              <slot name="additionalActions" :slotData="itemData.item"></slot>
          </span>
        </td>
      </tr>
    </template>

  </v-data-table>

  <v-dialog
      v-model="dialog"
      style="z-index:1007;"
      :max-width="props.dialogMaxWidth"
      scrollable
  >
    <v-card>
      <v-card-title
          style="position:fixed;z-index:1008;width:100%"
          variant="elevated"
      >
        <v-toolbar
            color="#FFFFFF">
          <v-row>
            <v-col
                cols="11"
                sm="11"
                md="11"
            >
              <span class="text-h5">
                <slot name="formTitle" :editedItem="editedItem" :editedIndex="editedIndex">
                  <span v-if="editedIndex === -1">New item</span>
                  <span v-else>Edit item</span>
                </slot>
              <v-spacer></v-spacer>

              </span>
            </v-col>
            <v-col
                cols="1"
                sm="1"
                md="1"
            >
              <v-btn
                  color="#333333"
                  variant="text"
                  @click.stop="close()"
                  icon="mdi-close-circle-outline"
                  size="x-large"
                  style="margin-top:-20px;"
              ></v-btn>
            </v-col>
          </v-row>
        </v-toolbar>

      </v-card-title>
      <v-form validate-on="submit lazy" @submit.prevent="submitForm" style="margin-top:73px">
        <slot name="form" :item="editedItem" :errors="errors" :contextData="props.contextData"></slot>

        <slot name="footerButtons">
          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
                v-if="props.closeFormButton"
                color="blue-darken-1"
                variant="text"
                @click="close()"
            >
              Cancel
            </v-btn>
            <v-btn
                :color="saveButtonColor"
                variant="text"
                type="submit"
            >
              Save
            </v-btn>
          </v-card-actions>
        </slot>
      </v-form>
    </v-card>
  </v-dialog>

  <v-dialog v-model="dialogDelete"
            width="auto">
    <v-card>
      <v-card-title class="text-h5">
        <slot name="deleteConfirmationMessage" :item="editedItem">Are you sure you want to delete this object ?</slot>
      </v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue-darken-1" variant="text" @click="closeDelete">Cancel</v-btn>
        <v-btn color="blue-darken-1" variant="text" @click="deleteItem()">OK</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>

</template>
<script setup>

// Version 15

import {defineProps, ref, computed, defineEmits, watch, defineExpose, useSlots, getCurrentInstance} from "vue"
import {empty, jsonQuery} from "@/helpers";

const vm = getCurrentInstance()
let slots = useSlots()
let emit = defineEmits(['update:modelValue', 'clickRow'])

const dialog = ref(false)
const dialogDelete = ref(false)
const editedItem = ref({})
const editedIndex = ref(-1)
const errors = ref(defaultErrors())
const saveButtonOn = ref(false)
const tableKey = ref(1)
const editedItemModifs = ref(0)

const columnSlots = computed(()=> {
  let ret = {}
  for(let slot in slots) {
    if(slot.substring(0,5) !== 'item.') continue
    ret[slot.substring(5)] = slots[slot]
  }
  return ret
})

const props = defineProps({
  actions: {
    type: Object,
    default(rawProps) {
      return {
        edit: true,
        delete: true,
        new: true
      }
    }
  },
  afterWrite: {
    type: Function,
    default: () => Promise.resolve(true)
  },
  afterCreate: {
    type: Function,
    default: () => Promise.resolve(true)
  },
  afterUpdate: {
    type: Function,
    default: () => Promise.resolve(true)
  },
  afterDelete: {
    type: Function,
    default: () => Promise.resolve(true)
  },
  beforeNewButton: {
    type: Function,
    default: () => Promise.resolve(true)
  },
  beforeNewButtonParameter: {
    type: Object,
    default(rawProps) {
      return {}
    }
  },
  buttonNewText: {
    type: String,
    default: "New Item"
  },
  clickRow: {
    type: Function,
    default: (item) => {
      return 'default'
    }
  },
  closeFormAfterSave: {
    type: Boolean,
    default: true
  },
  closeFormAfterSaveNew: {
    type: Boolean,
    default: true
  },
  closeFormButton: {
    type: Boolean,
    default: true
  },
  contextData: {
    type: Object,
    default(rawProps) {
      return {}
    }
  },
  defaultValues: {
    type: Object,
    default(rawProps) {
      return {}
    }
  },
  defaultDeleteFunction: {
    type: Boolean,
    default: true
  },
  customDeleteParameters: {
    type: Function,
    default: (editedItem) => {
      return {
        deleteQuery: '',
        variables: {}
      }
    }
  },
  dialogMaxWidth: {
    type: String,
    default: "800px"
  },
  editFunction: {
    type: Function,
    default: (event, item) => {
      return 'default'
    }
  },
  fields: {
    type: String,
    default: ""
  },
  fieldsWriteModification: {
    type: Object,
    default(rawProps) {
      return {}
    }
  },
  fixedVariables : {
    type: Object,
    default(rawProps) {
      return {}
    }
  },
  headers: Array,
  hideTitle: {
    type: Boolean,
    default: false
  },
  itemClasses: {
    type: Function,
    default: (item) => {
      return ''
    }
  },
  itemsPerPage: {
    type: Number,
    default: -1
  },
  modelValue: {
    type: Array,
    default(rawProps) {
      return []
    }
  },
  newFunction: {
    type: Function,
    default: (event, item) => {
      return 'default'
    }
  },
  sortBy: {
    type: Array,
    default(rawProps) {
      return []
    }
  },
  tableName: String,
  tableTitle: {
    type: String,
    default: "List"
  }
})

const returnedValue = computed({get:() => props.modelValue, set:(newValue) =>{emit('update:modelValue', newValue)}})

const tableHeaders = computed(() => {
  let ret = []
  for(let header of props.headers) {
    ret.push(header)
  }
  if(props.actions.new || props.actions.edit || props.actions.delete) ret.push({
    title: 'Actions',
    align: 'start',
    sortable: true,
    key: 'actions'
  })
  return ret
})

const rowClickable = computed(() => vm.propsOptions[0].clickRow.default !== props.clickRow)

function trClasses(item) {
  let ret = ' v-data-table__tr '
  if(rowClickable.value) ret += ' v-data-table__tr--clickable '
  ret += ' ' + props.itemClasses(item)
  return ret
}

function tdClasses(column) {
  let ret = ' v-data-v-data-table__td '
  if('align' in column) ret += 'v-data-table-column--align-' + column['align']

  return ret
}

watch(editedItem, (newValue) => {
  if(editedItemModifs.value > 0) saveButtonOn.value = true
  editedItemModifs.value ++
}, {deep:true})

const saveButtonColor = computed(() => {
  if(saveButtonOn.value) return 'blue-darken-1'
  else return 'rgba(130, 130, 130, 1)'
})

function variables() {
  let ret = {}
  ret[props.tableName] = {}
  for(let [field, val] of Object.entries(props.defaultValues)) {
    if(field in props.fieldsWriteModification) ret[props.tableName][field] = props.fieldsWriteModification[field](editedItem.value)
    else if(val instanceof Array) continue
    else ret[props.tableName][field] = editedItem.value[field]
  }
  for(let [field, val] of Object.entries(props.fixedVariables)) {
    if(!(field in ret[props.tableName])) ret[props.tableName][field] = val
  }
  if('id' in ret[props.tableName]) delete ret[props.tableName].id
  return ret
}

function defaultErrors() {
  let ret = {}
  for(let field of Object.keys(props.defaultValues)) {
    ret[field] = ""
  }
  return ret
}

const tableNameUCC = computed(() => props.tableName.charAt(0).toUpperCase() + props.tableName.slice(1))

function clickBtNew() {
  props.beforeNewButton(props.beforeNewButtonParameter).then((res) => {
    openForm(false)
  }).catch((error) => {

  })
}

function openForm(item) {
  saveButtonOn.value = false
  editedItem.value = {}
  if(item === false) {
    Object.assign(editedItem.value, props.defaultValues)
    editedIndex.value = -1
    dialog.value = true
  } else {
    Object.assign(editedItem.value, item)
    editedIndex.value = props.modelValue.findIndex(a => {
      return a.id === item.id
    })
    dialog.value = true
  }
  editedItemModifs.value = 0
}

function submitForm() {
  if(saveButtonOn.value) {
    errors.value = defaultErrors()
    saveButtonOn.value = false

    let isCreation = !('id' in editedItem.value) || editedItem.value.id === 0

    let checkQuery = `query ${tableNameUCC.value}DataCheck($${props.tableName}: ${tableNameUCC.value}Input!`
        if(!isCreation) checkQuery += `, $id` + props.tableName.charAt(0).toUpperCase() + props.tableName.slice(1) + ': Int'
    checkQuery += `) {
    ${props.tableName}DataCheck(${props.tableName}: $${props.tableName}`
      if(!isCreation) checkQuery += `, id` + props.tableName.charAt(0).toUpperCase() + props.tableName.slice(1) + `: $id` + props.tableName.charAt(0).toUpperCase() + props.tableName.slice(1)
    checkQuery += `) {
  `
    for(let [field, val] of Object.entries(props.defaultValues)) {
      if(val instanceof Object && 'id' in val) checkQuery += 'id' + field.charAt(0).toUpperCase() + field.slice(1)
      else if(val instanceof Array) continue
      else if(field !== 'id') checkQuery += field
      checkQuery += "\n"
    }
    checkQuery += `}}
  `

    let checkVariables = variables()
    if(!isCreation) {
      checkVariables['id' + props.tableName.charAt(0).toUpperCase() + props.tableName.slice(1)] = editedItem.value.id
    }

    return jsonQuery(checkQuery, checkVariables).then((res) => {
      let error = false
      for (let [field, errorTxt] of Object.entries(res.data[props.tableName + 'DataCheck'])) {
        if (!empty(errorTxt)) {
          error = true
          errors.value[field] = errorTxt
        }
      }

      if (!error) {
        let key
        if(isCreation) {

          if(props.newFunction(editedItem.value) === 'default') {
            key = tableNameUCC.value + 'Create'
            let createQuery = `mutation ${key}($${props.tableName}: ${tableNameUCC.value}Input!) {
      ${props.tableName}Create(${props.tableName}: $${props.tableName}) {
        ${props.fields}
      }}
    `
            return jsonQuery(createQuery, variables())
                .then(resCreate => resCreate.data[key.charAt(0).toLowerCase() + key.slice(1)])
                .then((itemCreated) => {
                  editedItem.value.id = itemCreated.id
                  props.afterCreate(editedItem.value).then((resAfterCreate) => {
                    returnedValue.value.push(itemCreated)
                    props.afterWrite(editedItem.value, itemCreated).then((resAfterWrite) => {
                      tableKey.value += 1
                      if(props.closeFormAfterSaveNew) close()
                      else editedItem.value = itemCreated
                    })
                  })
            })
          }
        } else {

          if(props.editFunction(editedItem.value) === 'default') {
            key = tableNameUCC.value + 'Update'
            let updateQuery = `mutation ${key}($${props.tableName}: ${tableNameUCC.value}Input!, $${props.tableName}UpdateId: Int) {
    ${props.tableName}Update(${props.tableName}: $${props.tableName}, id: $${props.tableName}UpdateId) {
          ${props.fields}
        }}
  `
            let updateVariables = {}
            Object.assign(updateVariables, variables())
            updateVariables[props.tableName + 'UpdateId'] = editedItem.value.id
            return jsonQuery(updateQuery, updateVariables)
                .then(resUpdate => resUpdate.data[key.charAt(0).toLowerCase() + key.slice(1)])
                .then((itemUpdated) => {
                  props.afterUpdate(editedItem.value).then((resAfterUpdate) => {
                    returnedValue.value[editedIndex.value] = itemUpdated
                    props.afterWrite(editedItem.value, itemUpdated).then((resAfterWrite) => {
                      if(props.closeFormAfterSave) close()
                      else editedItem.value = itemUpdated
                      tableKey.value += 1
                    })
                  })
            })
          }
        }
      } else return Promise.reject(errors)
    }).catch((error) => {

    })
  } else return Promise.reject(false).catch((error) => {})
}

function openFormDelete(item) {
  editedItem.value = {}
  Object.assign(editedItem.value, item)
  editedIndex.value = returnedValue.value.findIndex(a => {
    return a.id === item.id
  })
  dialogDelete.value = true
}

function deleteItem() {
  let deleteQuery
  let deletedItem = {}
  let variables = {}
  console.log('props.defaultDeleteFunction', props.defaultDeleteFunction)
  if(props.defaultDeleteFunction) {
    deleteQuery = `mutation ${tableNameUCC.value}Delete($${props.tableName}DeleteId: Int) {
    ${props.tableName}Delete(id: $${props.tableName}DeleteId) {
      ${props.fields}
    }}
`
    variables[props.tableName + 'DeleteId'] = editedItem.value.id
  } else ({deleteQuery, variables} = props.customDeleteParameters(editedItem.value))

  jsonQuery(deleteQuery, variables).then(res => {
    if(res === null) return
    deletedItem = JSON.parse(JSON.stringify(returnedValue.value[editedIndex.value]))
    returnedValue.value.splice(editedIndex.value, 1)
    closeDelete()
    return returnedValue.value
  }).then((res) => {
    props.afterDelete(res).then((res2) => {
      props.afterWrite(res, deletedItem)
      tableKey.value += 1
    })
  })
}

function close() {
  editedItem.value = {}
  Object.assign(editedItem.value, props.defaultValues)
  editedIndex.value = -1
  dialog.value = false
  errors.value = defaultErrors()
  editedItemModifs.value = 0
}

function closeDelete() {
  dialogDelete.value = false
  editedItem.value = {}
  Object.assign(editedItem.value, props.defaultValues)
  editedIndex.value = -1
  editedItemModifs.value = 0
}

function clickRow(item) {
  props.clickRow(item)
}

defineExpose({openForm, submitForm, dialog})

</script>

<style>

.v-data-table__tr--clickable {
  cursor: pointer;
}
</style>