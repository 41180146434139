<template>
  <v-btn
    prepend-icon="mdi-refresh"
    rounded="xl"
    elevation="8"
    size="x-large"
    :loading="loading"
    @click="refreshStock"
>
  <template v-slot:prepend>
    <v-icon color="success"></v-icon>
  </template>
  Refresh

</v-btn>
  <v-list lines="one">
    <v-list-item
        title="Nombre de produits différents actuellement dans le stock"
        :subtitle="stockValues.nbRefs"
    ></v-list-item>
    <v-list-item
        title="Nombre total d'articles en stock"
        :subtitle="stockValues.nbArticles"
    ></v-list-item>
    <v-list-item
        title="Valeur d'achat HT du stock"
        :subtitle="monetary(stockValues.valueBuyHT)"
    ></v-list-item>
    <v-list-item
        title="Valeur d'achat TTC du stock"
        :subtitle="monetary(stockValues.valueBuyTTC)"
    ></v-list-item>
    <v-list-item
        title="Valeur de vente HT du stock"
        :subtitle="monetary(stockValues.valueSellHT)"
    ></v-list-item>
    <v-list-item
        title="Valeur de vente TTC du stock"
        :subtitle="monetary(stockValues.valueSellTTC)"
    ></v-list-item>
  </v-list>


</template>
<script setup>

import {ref} from "vue"
import {jsonQuery, monetary} from "@/helpers";

const loading = ref(false)
const stockValues = ref({
  nbRefs: 0,
  nbArticles: 0,
  valueBuyHT: 0,
  valueBuyTTC: 0,
  valueSellHT: 0,
  valueSellTTC: 0
})

function refreshStock() {

  loading.value = true
  return jsonQuery(`
query StockValues {
  stockValues {
    nbRefs
    nbArticles
    valueBuyHT
    valueBuyTTC
    valueSellHT
    valueSellTTC
  }
}
`).then(res => {
    stockValues.value = res.data.stockValues
    loading.value = false
  })
}
refreshStock()

</script>